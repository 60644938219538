
/**
 * @name: 推广员管理-合作商超信息管理
 * @author: itmobai
 * @date: 2024-01-30 10:02
 * @description： 推广员管理-合作商超信息管理
 * @update: 2024-01-30 10:02
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import {promoterCreateApi, promoterDetailApi, promoterModifyApi, promoterQueryApi} from "@/apis/promoter/list"
import { IPromoterList, IPromoterListParam } from "@/apis/promoter/list/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy} from "@/utils/common";

@Component({})
export default class promoterSuperstorePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IPromoterList[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPromoterListParam = {
    page: 1,
    limit: 10,
    promoterTypes: 2
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    dialogWidth: '800px',
    delBtn: false,
    labelWidth: '110px',
    menuWidth: 100,
    column: [
      {
        label: "商超",
        prop: "keyWord",
        search: true,
        placeholder: "商超ID/商超名称",
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "负责人",
        prop: "personInChargeOrPhone",
        placeholder: "负责人名称/联系方式",
        search: true,
        addHide: true,
        editHide: true,
        hide: true,
        viewHide: true
      },
      {
        label: "所属推广员",
        prop: "pKeyWord",
        placeholder: "推广员ID/姓名",
        search: true,
        addHide: true,
        editHide: true,
        hide: true,
        viewHide: true
      },
      {
        label: "商超ID",
        prop: "promoterId",
        align: "left",
        width: 180,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "商超名称",
        prop: "promoterName",
        align: "center",
        overHidden: true,
        maxlength: 30,
        rules: [
          {required: true, message: '商超名称不能为空!', trigger: 'blur'}
        ],
        span: 12,
        slot: true
      },
      {
        label: "负责人",
        prop: "personInCharge",
        align: "center",
        overHidden: true,
        width: 120,
        maxlength: 20,
        rules: [
          {required: true, message: '负责人不能为空!', trigger: 'blur'}
        ],
        span: 12
      },
      {
        label: "联系方式",
        prop: "promoterPhone",
        align: "center",
        width: 150,
        maxlength: 11,
        rules: [
          {required: true, message: '联系方式不能为空!', trigger: 'blur'},
          {
            validator: (rule: any, value: any, callback: any) => {
              if (!/^1[3-9]\d{9}$/.test(value)) {
                return callback(new Error("请输入有效的手机号"))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        span: 12
      },
      {
        label: "所属推广员",
        prop: "promoterPid",
        hide: true,
        type: "select",
        dicUrl: "/api/admin/promoter/getPromoterList",
        // @ts-ignore
        filterable: true,
        dicFormatter: (res: any) => ({list: res.data, label: 'promoterName', value: 'promoterId'}),
        span: 12,
        rules: [
          {required: true, message: '所属推广员不能为空!', trigger: 'blur'},
        ]
      },
      {
        label: "邮箱",
        prop: "email",
        align: "center",
        width: 150,
        maxlength: 30,
        hide: true,
        rules: [
          {
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback()
              }
              if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                return callback(new Error("请输入有效的邮箱"))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        span: 24
      },
      {
        label: "营业执照",
        prop: "businessLicensePic",
        hide: true,
        addSlot: true,
        editSlot: true,
        span: 24,
        type: "image",
        imgPrefix: config.downloadUrl
      },
      {
        label: "所属推广员",
        prop: "ppromoterName",
        align: "center",
        width: 150,
        overHidden: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "状态",
        prop: "promoterStatus",
        align: "center",
        type: "select",
        search: true,
        dicData: [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          },
        ],
        addHide: true,
        editHide: true,
        slot: true,
        viewHide: true
      },
      {
        label: "【藏地粮市】邀请二维码",
        prop: "cappletCode",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        addHide: true,
        editHide: true,
        width: 160,
        viewHide: true
      },
      {
        label: "【藏地粮市尊享】邀请二维码",
        prop: "mbAppletCode",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        addHide: true,
        editHide: true,
        width: 180,
        hide: true,
        viewHide: true
      },
      {
        label: "【藏地粮市】邀请链接",
        prop: "promoterSn",
        addHide: true,
        editHide: true,
        overHidden: true,
        align: "center",
        slot: true,
        width: 200,
        viewHide: true
      },
      {
        label: "说明",
        prop: "remarks",
        align: "center",
        type: "textarea",
        hide: true,
        showWordLimit: true,
        overHidden: true,
        maxlength: 100,
        span: 24
      },
      {
        label: "创建时间",
        prop: "addTime",
        type: "daterange",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        viewHide: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IPromoterListParam = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    promoterQueryApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  rowSave (form: IPromoterList, done: Function, loading: Function) {
    promoterCreateApi({...form, promoterTypes: 2}).then(e => {
      if (e) {
        this.getList()
        done()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IPromoterList, done: Function, loading: Function) {
    promoterModifyApi(form).then(e => {
      if (e) {
        this.getList()
        done()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      loading()
    })
  }

  statusSwitch (promoterId: string, promoterStatus: number) {
    promoterModifyApi({promoterId, promoterStatus}).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    })
  }

  openEdit (row: IPromoterList, index: number) {
    promoterDetailApi(row.promoterId).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }

  openView (row: IPromoterList, index: number) {
    promoterDetailApi(row.promoterId).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowView(e, index)
    })
  }

  /**
   * 文件上传
   * @param file
   */
  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  created () {
    this.getList()
  }
}
