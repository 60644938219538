import {get, postJ} from "@/request";
import {IPromoterList, IPromoterListParam} from "@/apis/promoter/list/types";
import {IPageRes} from "@/apis/page";

/**
 * 推广员分页查询
 * @param params 查询参数
 */
export const promoterQueryApi = (params: IPromoterListParam) => get<IPageRes<IPromoterList[]>>("/admin/promoter/query", params)
/**
 * 创建
 * @param data
 */
export const promoterCreateApi = (data: Partial<IPromoterList>) => postJ("/admin/promoter/create", data)
/**
 * 修改
 * @param data
 */
export const promoterModifyApi = (data: Partial<IPromoterList>) => postJ("/admin/promoter/modify", data)
/**
 * 查询详情
 * @param promoterId
 */
export const promoterDetailApi = (promoterId: string) => get<IPromoterList>("/admin/promoter/detail/" + promoterId)
